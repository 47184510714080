type ItemChildren = {
  title: string;
  path: string;
  privilege: string;
};
type MenuItem = {
  title: string;
  path: string;
  icon: any;
  privilege: string;
  children: ItemChildren[];
};
type Menus = {
  subheader: string;
  items: MenuItem[];
};

/**
 * ## Show menu by privilege
 *
 * example case
 *
 * ```js
 * const nav = ['a', 'b', 'c']
 * const privilege = ['a', 'c']
 * const release = showMenuByPrivilege(privilege, nav)
 * // release const nav = ['a', 'c']
 * ```
 */
export default function showMenuByPrivilege(privileges: string[], menus: Menus[] | any[]) {
  // reduce MENU

  const reduceData = (menus as any[]).reduce((acc: Menus[], curr: Menus) => {
    // reduce ITEMS
    const releaseItem = (curr.items || []).reduce((acc: MenuItem[], curr: MenuItem) => {
      const { children, privilege } = curr;

      // reduce CHILDREN
      const release = (children || []).reduce((acc: ItemChildren[], curr: ItemChildren) => {
        const { privilege } = curr;

        // if privilege includes in privileges push child menu
        if (privileges.includes(privilege) || privilege === 'global') {
          acc.push(curr);
        }
        return acc;
      }, []);

      // if length child > 0, push parent items and menu
      if (release.length > 0) {
        acc.push({
          ...curr,
          children: release,
        });
      }
      if (!children || children.length < 1) {
        if (privileges.includes(privilege) || privilege === 'global') {
          acc.push({
            ...curr,
          });
        }
      }
      return acc;
    }, []);

    // if items length > 0 push parent and items
    if (releaseItem.length > 0) {
      acc.push({
        ...curr,
        items: releaseItem,
      });
    }
    return acc;
  }, []);

  return reduceData;
}
