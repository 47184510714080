// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.username}
      alt={user?.fullName}
      color={createAvatar(user?.fullName || 'A').color}
      {...other}
    >
      {createAvatar(user?.fullName || 'A').name}
    </Avatar>
  );
}
