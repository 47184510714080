// @mui
import { Box, Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function BlockContent() {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <Box sx={{ p: 1 }}>
        <Typography variant="body1" sx={{ color: 'text.secondary', fontSize: '10px' }}>
          Drop files here or click
        </Typography>
      </Box>
    </Stack>
  );
}
